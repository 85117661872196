import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import logo from '../assets/images/jonathan-agoot-logo.png'

const Header = (props) => (
	<header id="header" className="alt">
		<Link title="Jonathan Agoot - Home" to="/" className="logo">
			<span className="symbol">
				<img alt="Jonathan Agoot Logo" src={logo} />
			</span>
			<strong>Jonathan Agoot</strong>
		</Link>
		<nav>
			<a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
		</nav>
	</header>
)

Header.propTypes = {
	onToggleMenu: PropTypes.func
}

export default Header
