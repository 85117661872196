import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
	<nav id="menu">
		<div className="inner">
			<ul className="icons">
				<li>
					<a title="Jonathan Agoot on LinkedIn" target="_blank" href="https://www.linkedin.com/in/jonathanagoot" className="icon alt fa-linkedin">
						<span className="label">LinkedIn</span>
					</a>
				</li>
				<li>
					<a title="Jonathan Agoot on GitHub" target="_blank" href="https://github.com/rhokstar" className="icon alt fa-github">
						<span className="label">GitHub</span>
					</a>
				</li>
			</ul>
			<ul className="links">
				<li><Link title="Jonathan Agoot - Home" onClick={props.onToggleMenu} to="/">Home</Link></li>
				<li><Link title="Jonathan Agoot - About Me" onClick={props.onToggleMenu} to="/about-me">About Me</Link></li>
				<li><Link onClick={props.onToggleMenu} to="/expertise">Expertise</Link></li>
				<li><Link title="Jonathan Agoot - Portfolio" onClick={props.onToggleMenu} to="/portfolio">Portfolio</Link></li>
				<li><Link title="Jonathan Agoot - Recommendations" onClick={props.onToggleMenu} to="/recommendations">Recommendations</Link></li>
				<li><Link title="Jonathan Agoot - Speaking Engagements" onClick={props.onToggleMenu} to="/speaking-engagements">Speaking Engagements</Link></li>
			</ul>
			<ul className="actions vertical">
				<li><Link title="Jonathan Agoot - Contact me" onClick={props.onToggleMenu} to="/contact-me" className="button special fit">Contact Me</Link></li>
			</ul>
		</div>
		<a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
	</nav>
)

Menu.propTypes = {
	onToggleMenu: PropTypes.func
}

export default Menu
