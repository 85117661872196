import React from 'react'

const Footer = (props) => (<footer id="footer">
	<div className="inner">
		<ul className="icons">
			<li>
				<a title="Jonathan Agoot on LinkedIn" target="_blank" href="https://www.linkedin.com/in/jonathanagoot" className="icon alt fa-linkedin">
					<span className="label">LinkedIn</span>
				</a>
			</li>
			<li>
				<a title="Jonathan Agoot on GitHub" target="_blank" href="https://github.com/rhokstar" className="icon alt fa-github">
					<span className="label">GitHub</span>
				</a>
			</li>
		</ul>
		<ul className="copyright">
			<li>&copy; Jonathan Agoot | Built on Gatsby JS, React, and Serverless Architecture</li>
		</ul>
	</div>
</footer>)

export default Footer